import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage/session'
import rootReducer from 'reducers';
import rootSaga from 'sagas/rootSaga';
import thunk from 'redux-thunk';
import AGENTLOGIN from 'agentlogin';
import MENU_ACTIONS from 'menu-actions';
import CUSTOMER_SEARCH_WIDGET from 'customer-search-widget';
import TOPUP from 'topup';
import BUNDLE_DETAILS from 'bundle-details';
import PEGA_DETAILS from 'pega-details';
import SERVICE_TAGS from 'service-tags';
import SERVICE_DETAILS_HEADER from 'service-details-header';
import PLANS_CARD from 'plans-card';
import CONSUMPTION_CARD from 'consumption-card';
import PEGA_OFFERS from 'pega-offers';
import CUSTOMER_DASHBOARD_ADDONS from 'customer-dashboard-addons';
import BARRING_MANAGEMENT from 'barring-management';
import TRANSACTION_HISTORY from 'transaction-history';
import AGENT_BASIC_INFO from 'agent-basic-info';
import CUSTOMER_INFORMATION_BANNER from 'customer-information-banner';
import PRODUCTS_AND_SERVICES from 'products-and-services';
import NAVIGATION_HEADER from 'navigation-header';
import GENERIC_ACTIONS from 'generic-actions';
import ERROR_MODAL from 'error-modal';
import PLAN_TYPE_MODAL from 'plan-type-modal';
import page64a53f65f8dc8f4573741347Json from 'pages/page_64a53f65f8dc8f4573741347.json';
import page64a540fdf8dc8f4573746b7dJson from 'pages/page_64a540fdf8dc8f4573746b7d.json';
import page64a54181f8dc8f4573748806Json from 'pages/page_64a54181f8dc8f4573748806.json';
import page64a54ffbf8dc8f4573779ff5Json from 'pages/page_64a54ffbf8dc8f4573779ff5.json';
import page64a55033f8dc8f457377ac03Json from 'pages/page_64a55033f8dc8f457377ac03.json';
import page64a55096f8dc8f457377c255Json from 'pages/page_64a55096f8dc8f457377c255.json';
import page64a550e7f8dc8f457377d3cfJson from 'pages/page_64a550e7f8dc8f457377d3cf.json';
import page64dcc3eedd544b9205c7bf11Json from 'pages/page_64dcc3eedd544b9205c7bf11.json';
import routesJson from 'pages/routes.json';
import genericPagesJson from '../pages/genericPages.json';
import sharedPagesJson from '../pages/sharedPages.json';

import ValidationService from '../services/ValidationService.js';
import { history } from '../sharedHistory';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  pages: {
    page_64a53f65f8dc8f4573741347: page64a53f65f8dc8f4573741347Json,
    page_64a540fdf8dc8f4573746b7d: page64a540fdf8dc8f4573746b7dJson,
    page_64a54181f8dc8f4573748806: page64a54181f8dc8f4573748806Json,
    page_64a54ffbf8dc8f4573779ff5: page64a54ffbf8dc8f4573779ff5Json,
    page_64a55033f8dc8f457377ac03: page64a55033f8dc8f457377ac03Json,
    page_64a55096f8dc8f457377c255: page64a55096f8dc8f457377c255Json,
    page_64a550e7f8dc8f457377d3cf: page64a550e7f8dc8f457377d3cfJson,
    page_64dcc3eedd544b9205c7bf11: page64dcc3eedd544b9205c7bf11Json,
    routes: routesJson,
    genericPages: genericPagesJson,
    sharedPages:sharedPagesJson,
    menuItems: [
    {
      route: '/dashboard',
      name: 'agentdashboard'
    },
    {
      route: '/page/customersearch',
      name: 'customersearch'
    },
    {
      route: '/page/customerdashboard',
      name: 'customerdashboard'
    },
    {
      route: '/page/activebundledetails',
      name: 'activebundledetails'
    },
    {
      route: '/page/sellablebundledetails',
      name: 'sellablebundledetails'
    },
    {
      route: '/page/campaigndetails',
      name: 'campaigndetails'
    },
    {
      route: '/page/prepaiddashboard',
      name: 'prepaiddashboard'
    },
    ]
  }
};

const authItem = localStorage.getItem('auth');
if (authItem) {
  initialState.authentication = JSON.parse(authItem);
}

const sagaMiddleware = createSagaMiddleware();

const wrapper = (pckg, mid) => store => next => action => {
  if(action.type.startsWith(`@${pckg}/`)) {
    return mid(store)(next)(action);
  } else {
    return next(action);
  }
};


const validator = store => next => action => {
  if(ValidationService.validateable(action)) {
    ValidationService.validate(action)
    .then(res => {
      if(res) {
        console.log('Successfull validation:', action);
      } else {
        console.log('Invalid payload contents', action);
      }
    });
  }
  return next(action);
}


const middlewares = [
  ...(AGENTLOGIN.middlewares ? AGENTLOGIN.middlewares.map(mid => wrapper('agentlogin',mid)) : []),
  ...(MENU_ACTIONS.middlewares ? MENU_ACTIONS.middlewares.map(mid => wrapper('menu-actions',mid)) : []),
  ...(CUSTOMER_SEARCH_WIDGET.middlewares ? CUSTOMER_SEARCH_WIDGET.middlewares.map(mid => wrapper('customer-search-widget',mid)) : []),
  ...(TOPUP.middlewares ? TOPUP.middlewares.map(mid => wrapper('topup',mid)) : []),
  ...(BUNDLE_DETAILS.middlewares ? BUNDLE_DETAILS.middlewares.map(mid => wrapper('bundle-details',mid)) : []),
  ...(PEGA_DETAILS.middlewares ? PEGA_DETAILS.middlewares.map(mid => wrapper('pega-details',mid)) : []),
  ...(SERVICE_TAGS.middlewares ? SERVICE_TAGS.middlewares.map(mid => wrapper('service-tags',mid)) : []),
  ...(SERVICE_DETAILS_HEADER.middlewares ? SERVICE_DETAILS_HEADER.middlewares.map(mid => wrapper('service-details-header',mid)) : []),
  ...(PLANS_CARD.middlewares ? PLANS_CARD.middlewares.map(mid => wrapper('plans-card',mid)) : []),
  ...(CONSUMPTION_CARD.middlewares ? CONSUMPTION_CARD.middlewares.map(mid => wrapper('consumption-card',mid)) : []),
  ...(PEGA_OFFERS.middlewares ? PEGA_OFFERS.middlewares.map(mid => wrapper('pega-offers',mid)) : []),
  ...(CUSTOMER_DASHBOARD_ADDONS.middlewares ? CUSTOMER_DASHBOARD_ADDONS.middlewares.map(mid => wrapper('customer-dashboard-addons',mid)) : []),
  ...(BARRING_MANAGEMENT.middlewares ? BARRING_MANAGEMENT.middlewares.map(mid => wrapper('barring-management',mid)) : []),
  ...(TRANSACTION_HISTORY.middlewares ? TRANSACTION_HISTORY.middlewares.map(mid => wrapper('transaction-history',mid)) : []),
  ...(AGENT_BASIC_INFO.middlewares ? AGENT_BASIC_INFO.middlewares.map(mid => wrapper('agent-basic-info',mid)) : []),
  ...(CUSTOMER_INFORMATION_BANNER.middlewares ? CUSTOMER_INFORMATION_BANNER.middlewares.map(mid => wrapper('customer-information-banner',mid)) : []),
  ...(PRODUCTS_AND_SERVICES.middlewares ? PRODUCTS_AND_SERVICES.middlewares.map(mid => wrapper('products-and-services',mid)) : []),
  ...(NAVIGATION_HEADER.middlewares ? NAVIGATION_HEADER.middlewares.map(mid => wrapper('navigation-header',mid)) : []),
  ...(GENERIC_ACTIONS.middlewares ? GENERIC_ACTIONS.middlewares.map(mid => wrapper('generic-actions',mid)) : []),
  ...(ERROR_MODAL.middlewares ? ERROR_MODAL.middlewares.map(mid => wrapper('error-modal',mid)) : []),
  ...(PLAN_TYPE_MODAL.middlewares ? PLAN_TYPE_MODAL.middlewares.map(mid => wrapper('plan-type-modal',mid)) : []),
];

const persistConfig = {
  key: "root",
  storage: storage,
  version: 0,
  whitelist: ["props"] // This is for core app reducers, widgets have their own 'persistedReducer'
};

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
);

const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), validator, sagaMiddleware, thunk, ...middlewares))
);

const persistor = persistStore(store);


sagaMiddleware.run(rootSaga);

export { store, persistor };

