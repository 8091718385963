import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../sharedHistory';
import AGENTLOGIN from 'agentlogin';
import AGENTLOGIN_pckg from '../../node_modules/agentlogin/package.json';
import MENU_ACTIONS from 'menu-actions';
import MENU_ACTIONS_pckg from '../../node_modules/menu-actions/package.json';
import CUSTOMER_SEARCH_WIDGET from 'customer-search-widget';
import CUSTOMER_SEARCH_WIDGET_pckg from '../../node_modules/customer-search-widget/package.json';
import TOPUP from 'topup';
import TOPUP_pckg from '../../node_modules/topup/package.json';
import BUNDLE_DETAILS from 'bundle-details';
import BUNDLE_DETAILS_pckg from '../../node_modules/bundle-details/package.json';
import PEGA_DETAILS from 'pega-details';
import PEGA_DETAILS_pckg from '../../node_modules/pega-details/package.json';
import SERVICE_TAGS from 'service-tags';
import SERVICE_TAGS_pckg from '../../node_modules/service-tags/package.json';
import SERVICE_DETAILS_HEADER from 'service-details-header';
import SERVICE_DETAILS_HEADER_pckg from '../../node_modules/service-details-header/package.json';
import PLANS_CARD from 'plans-card';
import PLANS_CARD_pckg from '../../node_modules/plans-card/package.json';
import CONSUMPTION_CARD from 'consumption-card';
import CONSUMPTION_CARD_pckg from '../../node_modules/consumption-card/package.json';
import PEGA_OFFERS from 'pega-offers';
import PEGA_OFFERS_pckg from '../../node_modules/pega-offers/package.json';
import CUSTOMER_DASHBOARD_ADDONS from 'customer-dashboard-addons';
import CUSTOMER_DASHBOARD_ADDONS_pckg from '../../node_modules/customer-dashboard-addons/package.json';
import BARRING_MANAGEMENT from 'barring-management';
import BARRING_MANAGEMENT_pckg from '../../node_modules/barring-management/package.json';
import TRANSACTION_HISTORY from 'transaction-history';
import TRANSACTION_HISTORY_pckg from '../../node_modules/transaction-history/package.json';
import AGENT_BASIC_INFO from 'agent-basic-info';
import AGENT_BASIC_INFO_pckg from '../../node_modules/agent-basic-info/package.json';
import CUSTOMER_INFORMATION_BANNER from 'customer-information-banner';
import CUSTOMER_INFORMATION_BANNER_pckg from '../../node_modules/customer-information-banner/package.json';
import PRODUCTS_AND_SERVICES from 'products-and-services';
import PRODUCTS_AND_SERVICES_pckg from '../../node_modules/products-and-services/package.json';
import NAVIGATION_HEADER from 'navigation-header';
import NAVIGATION_HEADER_pckg from '../../node_modules/navigation-header/package.json';
import GENERIC_ACTIONS from 'generic-actions';
import GENERIC_ACTIONS_pckg from '../../node_modules/generic-actions/package.json';
import ERROR_MODAL from 'error-modal';
import ERROR_MODAL_pckg from '../../node_modules/error-modal/package.json';
import PLAN_TYPE_MODAL from 'plan-type-modal';
import PLAN_TYPE_MODAL_pckg from '../../node_modules/plan-type-modal/package.json';

import authentication from './authenticationReducer';
import config from './configReducer';
import language from './languageReducer'
import props from "./propReducer";
import pages from "./pagesReducer";
import loader from "./loaderReducer";

const AGENTLOGIN_reduxPerInstance = AGENTLOGIN_pckg['app-framework'] && AGENTLOGIN_pckg['app-framework'].reduxPerInstance;
const MENU_ACTIONS_reduxPerInstance = MENU_ACTIONS_pckg['app-framework'] && MENU_ACTIONS_pckg['app-framework'].reduxPerInstance;
const CUSTOMER_SEARCH_WIDGET_reduxPerInstance = CUSTOMER_SEARCH_WIDGET_pckg['app-framework'] && CUSTOMER_SEARCH_WIDGET_pckg['app-framework'].reduxPerInstance;
const TOPUP_reduxPerInstance = TOPUP_pckg['app-framework'] && TOPUP_pckg['app-framework'].reduxPerInstance;
const BUNDLE_DETAILS_reduxPerInstance = BUNDLE_DETAILS_pckg['app-framework'] && BUNDLE_DETAILS_pckg['app-framework'].reduxPerInstance;
const PEGA_DETAILS_reduxPerInstance = PEGA_DETAILS_pckg['app-framework'] && PEGA_DETAILS_pckg['app-framework'].reduxPerInstance;
const SERVICE_TAGS_reduxPerInstance = SERVICE_TAGS_pckg['app-framework'] && SERVICE_TAGS_pckg['app-framework'].reduxPerInstance;
const SERVICE_DETAILS_HEADER_reduxPerInstance = SERVICE_DETAILS_HEADER_pckg['app-framework'] && SERVICE_DETAILS_HEADER_pckg['app-framework'].reduxPerInstance;
const PLANS_CARD_reduxPerInstance = PLANS_CARD_pckg['app-framework'] && PLANS_CARD_pckg['app-framework'].reduxPerInstance;
const CONSUMPTION_CARD_reduxPerInstance = CONSUMPTION_CARD_pckg['app-framework'] && CONSUMPTION_CARD_pckg['app-framework'].reduxPerInstance;
const PEGA_OFFERS_reduxPerInstance = PEGA_OFFERS_pckg['app-framework'] && PEGA_OFFERS_pckg['app-framework'].reduxPerInstance;
const CUSTOMER_DASHBOARD_ADDONS_reduxPerInstance = CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'] && CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'].reduxPerInstance;
const BARRING_MANAGEMENT_reduxPerInstance = BARRING_MANAGEMENT_pckg['app-framework'] && BARRING_MANAGEMENT_pckg['app-framework'].reduxPerInstance;
const TRANSACTION_HISTORY_reduxPerInstance = TRANSACTION_HISTORY_pckg['app-framework'] && TRANSACTION_HISTORY_pckg['app-framework'].reduxPerInstance;
const AGENT_BASIC_INFO_reduxPerInstance = AGENT_BASIC_INFO_pckg['app-framework'] && AGENT_BASIC_INFO_pckg['app-framework'].reduxPerInstance;
const CUSTOMER_INFORMATION_BANNER_reduxPerInstance = CUSTOMER_INFORMATION_BANNER_pckg['app-framework'] && CUSTOMER_INFORMATION_BANNER_pckg['app-framework'].reduxPerInstance;
const PRODUCTS_AND_SERVICES_reduxPerInstance = PRODUCTS_AND_SERVICES_pckg['app-framework'] && PRODUCTS_AND_SERVICES_pckg['app-framework'].reduxPerInstance;
const NAVIGATION_HEADER_reduxPerInstance = NAVIGATION_HEADER_pckg['app-framework'] && NAVIGATION_HEADER_pckg['app-framework'].reduxPerInstance;
const GENERIC_ACTIONS_reduxPerInstance = GENERIC_ACTIONS_pckg['app-framework'] && GENERIC_ACTIONS_pckg['app-framework'].reduxPerInstance;
const ERROR_MODAL_reduxPerInstance = ERROR_MODAL_pckg['app-framework'] && ERROR_MODAL_pckg['app-framework'].reduxPerInstance;
const PLAN_TYPE_MODAL_reduxPerInstance = PLAN_TYPE_MODAL_pckg['app-framework'] && PLAN_TYPE_MODAL_pckg['app-framework'].reduxPerInstance;

const identityReducer = (state = null, action) => state;

const systemReducers = {
  router: connectRouter(history),
  authentication,
  pages,
  language,
  config,
  loader,
  props
};

const singleReducer = (reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(reduxPerInstance) return null;
  return reducer;
};

const perInstanceReducer = (pckg, instanceId, reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(!reduxPerInstance) return null;
  return (state, action) => {
    if(action.type.startsWith(`@${pckg}/`) || state === undefined) {
      if(action.instanceId) {
        if(Array.isArray(action.instanceId) && action.instanceId.includes(instanceId)) {
          return reducer(state, action);
        } else if (action.instanceId === instanceId) {
          return reducer(state, action);
        } else {
          return state;
        }
      } else {
        return reducer(state, action);
      }
    } else {
      return state;
    }
  };
};

const singleReducersInit = {
  'agentlogin': singleReducer(AGENTLOGIN_reduxPerInstance, AGENTLOGIN.reducer),
  'menu-actions': singleReducer(MENU_ACTIONS_reduxPerInstance, MENU_ACTIONS.reducer),
  'customer-search-widget': singleReducer(CUSTOMER_SEARCH_WIDGET_reduxPerInstance, CUSTOMER_SEARCH_WIDGET.reducer),
  'topup': singleReducer(TOPUP_reduxPerInstance, TOPUP.reducer),
  'bundle-details': singleReducer(BUNDLE_DETAILS_reduxPerInstance, BUNDLE_DETAILS.reducer),
  'pega-details': singleReducer(PEGA_DETAILS_reduxPerInstance, PEGA_DETAILS.reducer),
  'service-tags': singleReducer(SERVICE_TAGS_reduxPerInstance, SERVICE_TAGS.reducer),
  'service-details-header': singleReducer(SERVICE_DETAILS_HEADER_reduxPerInstance, SERVICE_DETAILS_HEADER.reducer),
  'plans-card': singleReducer(PLANS_CARD_reduxPerInstance, PLANS_CARD.reducer),
  'consumption-card': singleReducer(CONSUMPTION_CARD_reduxPerInstance, CONSUMPTION_CARD.reducer),
  'pega-offers': singleReducer(PEGA_OFFERS_reduxPerInstance, PEGA_OFFERS.reducer),
  'customer-dashboard-addons': singleReducer(CUSTOMER_DASHBOARD_ADDONS_reduxPerInstance, CUSTOMER_DASHBOARD_ADDONS.reducer),
  'barring-management': singleReducer(BARRING_MANAGEMENT_reduxPerInstance, BARRING_MANAGEMENT.reducer),
  'transaction-history': singleReducer(TRANSACTION_HISTORY_reduxPerInstance, TRANSACTION_HISTORY.reducer),
  'agent-basic-info': singleReducer(AGENT_BASIC_INFO_reduxPerInstance, AGENT_BASIC_INFO.reducer),
  'customer-information-banner': singleReducer(CUSTOMER_INFORMATION_BANNER_reduxPerInstance, CUSTOMER_INFORMATION_BANNER.reducer),
  'products-and-services': singleReducer(PRODUCTS_AND_SERVICES_reduxPerInstance, PRODUCTS_AND_SERVICES.reducer),
  'navigation-header': singleReducer(NAVIGATION_HEADER_reduxPerInstance, NAVIGATION_HEADER.reducer),
  'generic-actions': singleReducer(GENERIC_ACTIONS_reduxPerInstance, GENERIC_ACTIONS.reducer),
  'error-modal': singleReducer(ERROR_MODAL_reduxPerInstance, ERROR_MODAL.reducer),
  'plan-type-modal': singleReducer(PLAN_TYPE_MODAL_reduxPerInstance, PLAN_TYPE_MODAL.reducer),
};

const userReducersPerInstanceInit = {
  'agentlogin': AGENTLOGIN.reducer && AGENTLOGIN_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '316ff961_c3ff_46ef_8a81_8eb6a536315c': perInstanceReducer('agentlogin', '316ff961-c3ff-46ef-8a81-8eb6a536315c', AGENTLOGIN_pckg['app-framework'], AGENTLOGIN.reducer),
  }).filter(pair => pair[1]))),
  'menu-actions': MENU_ACTIONS.reducer && MENU_ACTIONS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'd9c6bb3b_43e1_4811_aff5_5d577ad0efa3': perInstanceReducer('menu-actions', 'd9c6bb3b-43e1-4811-aff5-5d577ad0efa3', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
    'b7e1b943_211d_4bbf_9700_fbc9fe407a5b': perInstanceReducer('menu-actions', 'b7e1b943-211d-4bbf-9700-fbc9fe407a5b', MENU_ACTIONS_pckg['app-framework'], MENU_ACTIONS.reducer),
  }).filter(pair => pair[1]))),
  'customer-search-widget': CUSTOMER_SEARCH_WIDGET.reducer && CUSTOMER_SEARCH_WIDGET_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'e51b9882_6c59_4403_9b80_e2c0860ca6a3': perInstanceReducer('customer-search-widget', 'e51b9882-6c59-4403-9b80-e2c0860ca6a3', CUSTOMER_SEARCH_WIDGET_pckg['app-framework'], CUSTOMER_SEARCH_WIDGET.reducer),
  }).filter(pair => pair[1]))),
  'topup': TOPUP.reducer && TOPUP_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '4b1d616c_4544_4cf1_9efe_544e169aa9f8': perInstanceReducer('topup', '4b1d616c-4544-4cf1-9efe-544e169aa9f8', TOPUP_pckg['app-framework'], TOPUP.reducer),
    'f55245f1_1cc8_40e5_9823_34086622fbf9': perInstanceReducer('topup', 'f55245f1-1cc8-40e5-9823-34086622fbf9', TOPUP_pckg['app-framework'], TOPUP.reducer),
  }).filter(pair => pair[1]))),
  'bundle-details': BUNDLE_DETAILS.reducer && BUNDLE_DETAILS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '07e96bcd_3266_4f49_9b9d_d0961aae57ba': perInstanceReducer('bundle-details', '07e96bcd-3266-4f49-9b9d-d0961aae57ba', BUNDLE_DETAILS_pckg['app-framework'], BUNDLE_DETAILS.reducer),
    'ea59cb32_123c_462c_9e22_93d9f8a8443e': perInstanceReducer('bundle-details', 'ea59cb32-123c-462c-9e22-93d9f8a8443e', BUNDLE_DETAILS_pckg['app-framework'], BUNDLE_DETAILS.reducer),
  }).filter(pair => pair[1]))),
  'pega-details': PEGA_DETAILS.reducer && PEGA_DETAILS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'ea52218b_eea7_4c7f_aab5_297117262d0f': perInstanceReducer('pega-details', 'ea52218b-eea7-4c7f-aab5-297117262d0f', PEGA_DETAILS_pckg['app-framework'], PEGA_DETAILS.reducer),
  }).filter(pair => pair[1]))),
  'service-tags': SERVICE_TAGS.reducer && SERVICE_TAGS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '20908ffc_0085_426e_b763_84d001292ad1': perInstanceReducer('service-tags', '20908ffc-0085-426e-b763-84d001292ad1', SERVICE_TAGS_pckg['app-framework'], SERVICE_TAGS.reducer),
  }).filter(pair => pair[1]))),
  'service-details-header': SERVICE_DETAILS_HEADER.reducer && SERVICE_DETAILS_HEADER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'ba3231eb_55ef_4364_87f5_faedd54221a5': perInstanceReducer('service-details-header', 'ba3231eb-55ef-4364-87f5-faedd54221a5', SERVICE_DETAILS_HEADER_pckg['app-framework'], SERVICE_DETAILS_HEADER.reducer),
  }).filter(pair => pair[1]))),
  'plans-card': PLANS_CARD.reducer && PLANS_CARD_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '365e8f37_07c6_4ace_845f_985bddcbf5a9': perInstanceReducer('plans-card', '365e8f37-07c6-4ace-845f-985bddcbf5a9', PLANS_CARD_pckg['app-framework'], PLANS_CARD.reducer),
  }).filter(pair => pair[1]))),
  'consumption-card': CONSUMPTION_CARD.reducer && CONSUMPTION_CARD_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '71b40e92_a751_4a0c_a03f_4bd30e3f7b9e': perInstanceReducer('consumption-card', '71b40e92-a751-4a0c-a03f-4bd30e3f7b9e', CONSUMPTION_CARD_pckg['app-framework'], CONSUMPTION_CARD.reducer),
  }).filter(pair => pair[1]))),
  'pega-offers': PEGA_OFFERS.reducer && PEGA_OFFERS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '6e35abdf_815a_4a4e_87c4_38b25b79073e': perInstanceReducer('pega-offers', '6e35abdf-815a-4a4e-87c4-38b25b79073e', PEGA_OFFERS_pckg['app-framework'], PEGA_OFFERS.reducer),
  }).filter(pair => pair[1]))),
  'customer-dashboard-addons': CUSTOMER_DASHBOARD_ADDONS.reducer && CUSTOMER_DASHBOARD_ADDONS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'd7cd168f_aa1e_479a_98e9_f4bd5ed1533a': perInstanceReducer('customer-dashboard-addons', 'd7cd168f-aa1e-479a-98e9-f4bd5ed1533a', CUSTOMER_DASHBOARD_ADDONS_pckg['app-framework'], CUSTOMER_DASHBOARD_ADDONS.reducer),
  }).filter(pair => pair[1]))),
  'barring-management': BARRING_MANAGEMENT.reducer && BARRING_MANAGEMENT_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '0c6fdb0f_cc72_47dd_b19d_1d51cf208da3': perInstanceReducer('barring-management', '0c6fdb0f-cc72-47dd-b19d-1d51cf208da3', BARRING_MANAGEMENT_pckg['app-framework'], BARRING_MANAGEMENT.reducer),
  }).filter(pair => pair[1]))),
  'transaction-history': TRANSACTION_HISTORY.reducer && TRANSACTION_HISTORY_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'dd5d5fab_802e_42d3_99bf_5dd100c5b6eb': perInstanceReducer('transaction-history', 'dd5d5fab-802e-42d3-99bf-5dd100c5b6eb', TRANSACTION_HISTORY_pckg['app-framework'], TRANSACTION_HISTORY.reducer),
  }).filter(pair => pair[1]))),
  'agent-basic-info': AGENT_BASIC_INFO.reducer && AGENT_BASIC_INFO_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '8fa759d8_d8f2_4722_bf4a_220932a664d3': perInstanceReducer('agent-basic-info', '8fa759d8-d8f2-4722-bf4a-220932a664d3', AGENT_BASIC_INFO_pckg['app-framework'], AGENT_BASIC_INFO.reducer),
  }).filter(pair => pair[1]))),
  'customer-information-banner': CUSTOMER_INFORMATION_BANNER.reducer && CUSTOMER_INFORMATION_BANNER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'c1be6d41_d55d_41e1_9b67_c7491d0c62b1': perInstanceReducer('customer-information-banner', 'c1be6d41-d55d-41e1-9b67-c7491d0c62b1', CUSTOMER_INFORMATION_BANNER_pckg['app-framework'], CUSTOMER_INFORMATION_BANNER.reducer),
  }).filter(pair => pair[1]))),
  'products-and-services': PRODUCTS_AND_SERVICES.reducer && PRODUCTS_AND_SERVICES_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'b00e86ee_b5f3_4f4e_940e_68862908f0a4': perInstanceReducer('products-and-services', 'b00e86ee-b5f3-4f4e-940e-68862908f0a4', PRODUCTS_AND_SERVICES_pckg['app-framework'], PRODUCTS_AND_SERVICES.reducer),
  }).filter(pair => pair[1]))),
  'navigation-header': NAVIGATION_HEADER.reducer && NAVIGATION_HEADER_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '812b86d6_7305_4690_9968_37e121ce6fbb': perInstanceReducer('navigation-header', '812b86d6-7305-4690-9968-37e121ce6fbb', NAVIGATION_HEADER_pckg['app-framework'], NAVIGATION_HEADER.reducer),
  }).filter(pair => pair[1]))),
  'generic-actions': GENERIC_ACTIONS.reducer && GENERIC_ACTIONS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '9b08c7a6_2ccb_4449_a0e0_95b466713c47': perInstanceReducer('generic-actions', '9b08c7a6-2ccb-4449-a0e0-95b466713c47', GENERIC_ACTIONS_pckg['app-framework'], GENERIC_ACTIONS.reducer),
  }).filter(pair => pair[1]))),
  'error-modal': ERROR_MODAL.reducer && ERROR_MODAL_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    'bda939de_0262_496d_bda2_72ee64090ff8': perInstanceReducer('error-modal', 'bda939de-0262-496d-bda2-72ee64090ff8', ERROR_MODAL_pckg['app-framework'], ERROR_MODAL.reducer),
  }).filter(pair => pair[1]))),
  'plan-type-modal': PLAN_TYPE_MODAL.reducer && PLAN_TYPE_MODAL_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '6a7a9e49_a235_4dd7_b893_204d6d68e389': perInstanceReducer('plan-type-modal', '6a7a9e49-a235-4dd7-b893-204d6d68e389', PLAN_TYPE_MODAL_pckg['app-framework'], PLAN_TYPE_MODAL.reducer),
  }).filter(pair => pair[1]))),
};

const userReducersSingle = Object.fromEntries(Object.entries(singleReducersInit).filter(pair => pair[1]));
const userReducersPerInstance = Object.fromEntries(Object.entries(userReducersPerInstanceInit).filter(pair => pair[1]));

export default combineReducers({
  ...systemReducers,
  ...userReducersSingle,
  ...userReducersPerInstance,
});
