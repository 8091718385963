import { all } from 'redux-saga/effects';
  import AGENTLOGIN from 'agentlogin';
  import MENU_ACTIONS from 'menu-actions';
  import CUSTOMER_SEARCH_WIDGET from 'customer-search-widget';
  import TOPUP from 'topup';
  import BUNDLE_DETAILS from 'bundle-details';
  import PEGA_DETAILS from 'pega-details';
  import SERVICE_TAGS from 'service-tags';
  import SERVICE_DETAILS_HEADER from 'service-details-header';
  import PLANS_CARD from 'plans-card';
  import CONSUMPTION_CARD from 'consumption-card';
  import PEGA_OFFERS from 'pega-offers';
  import CUSTOMER_DASHBOARD_ADDONS from 'customer-dashboard-addons';
  import BARRING_MANAGEMENT from 'barring-management';
  import TRANSACTION_HISTORY from 'transaction-history';
  import AGENT_BASIC_INFO from 'agent-basic-info';
  import CUSTOMER_INFORMATION_BANNER from 'customer-information-banner';
  import PRODUCTS_AND_SERVICES from 'products-and-services';
  import NAVIGATION_HEADER from 'navigation-header';
  import GENERIC_ACTIONS from 'generic-actions';
  import ERROR_MODAL from 'error-modal';
  import PLAN_TYPE_MODAL from 'plan-type-modal';

import configSaga from './configSaga';
import languageSaga from './languageSaga';
import navSaga from "./navSaga";

export default function* rootSaga() {
  yield all([
    ...configSaga,
    ...languageSaga,
    ...navSaga,
  ...(AGENTLOGIN.sagas || []),
  ...(MENU_ACTIONS.sagas || []),
  ...(CUSTOMER_SEARCH_WIDGET.sagas || []),
  ...(TOPUP.sagas || []),
  ...(BUNDLE_DETAILS.sagas || []),
  ...(PEGA_DETAILS.sagas || []),
  ...(SERVICE_TAGS.sagas || []),
  ...(SERVICE_DETAILS_HEADER.sagas || []),
  ...(PLANS_CARD.sagas || []),
  ...(CONSUMPTION_CARD.sagas || []),
  ...(PEGA_OFFERS.sagas || []),
  ...(CUSTOMER_DASHBOARD_ADDONS.sagas || []),
  ...(BARRING_MANAGEMENT.sagas || []),
  ...(TRANSACTION_HISTORY.sagas || []),
  ...(AGENT_BASIC_INFO.sagas || []),
  ...(CUSTOMER_INFORMATION_BANNER.sagas || []),
  ...(PRODUCTS_AND_SERVICES.sagas || []),
  ...(NAVIGATION_HEADER.sagas || []),
  ...(GENERIC_ACTIONS.sagas || []),
  ...(ERROR_MODAL.sagas || []),
  ...(PLAN_TYPE_MODAL.sagas || []),
  ]);
}
